.btns label, .btns button {
    margin: 10px;
}

.btns label {
    margin-top: 35px;
    border-radius: 12px;
    background-color: #F4F4F4 !important;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    color:#5B6577 !important;
    text-align: center;
    font-size: 18px;
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-transform: initial;
}
